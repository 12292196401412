import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Latest Release`}</h2>
    <p>{`This is the changelog information of latest version and if you have any ideas or feedback for improvements in the next version please tell us from the feedback section`}</p>
    <div style={{
      "backgroundColor": "rgb(244, 242, 252)",
      "color": "rgb(135, 91, 247)",
      "padding": "1rem",
      "borderBottom": "1px solid #875bf7",
      "padding": "1rem"
    }}>
  <p style={{
        "fontWeight": "700"
      }}>9 August 2024</p>
    </div>
    <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /></h3>
    <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 2.0.0`}</strong>{` `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: Android Compose for Legion is now Available!`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Alert`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Anchor`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Badge`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Bottom Sheet`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Chips`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Dropdown`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Pin Input`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Progress Circle`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Radio Button`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Rating`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Slider`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Snackbar`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Spinner`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{` Component for Android Compose.`}</p>
    <p><strong parentName="p">{`New`}</strong>{`: New `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{` Component for Android Compose.`}</p>
    <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br /></h3>
    <ul>
      <li parentName="ul"></li>
    </ul>
    <div className="divi" />
    <h2>{`Previous Release`}</h2>
    <p>{`Find the changelog information on the previous version by clicking the section below`}</p>
    <Accordion mdxType="Accordion">
      <AccordionItem title="21 November 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 1.42.1`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Support Tablet/Fold on Foundation Page `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Proguard ViewState `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <ul>
          <li parentName="ul">
            <br />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem title="18 August 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 1.41.2`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Capability Change Custom Color In `}<inlineCode parentName="p">{`Button`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Capability to Hide Icon on `}<inlineCode parentName="p">{`Alert`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Capability to set error without label `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New `}<inlineCode parentName="p">{`Modal`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New `}<inlineCode parentName="p">{`Tooltip`}</inlineCode>{` Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Capability to Load File in LgnPhotoField `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Text Allignment on `}<inlineCode parentName="p">{`Button`}</inlineCode>{` Label `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bugfix`}</strong>{`: Space Icon in `}<inlineCode parentName="p">{`Button`}</inlineCode>{` `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version: 2.0.2`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Split component Android and iOS `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Accordion `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Anchor Text `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Button `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Checkbox `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Chip `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure for Images `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Radio Button `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Switch `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Text Area `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Updated`}</strong>{`: Change structure, implementation component properties, implementation nested component, for Text Fields `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New Component`}</strong>{`: New Component with component properties and nested component for Alert `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New Component`}</strong>{`: New Component with component properties and nested component for Divider `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Avatar for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Badge for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Bottom Bar for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Bottom Sheet for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Modal for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Navigation Bar for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Snackbar for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Tab Bar for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Deprecated`}</strong>{`: Temporarily removed component Tooltip for bug fixes to repair structural components and will be released again in the next version `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="21 June 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 1.35.5`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Ime Options Capability on Text Field Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Compability Snackbar on Bottom Sheet `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Component Navigation Bar `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Revamp Multi photo field to LgnPhotoField `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Space on Small Button when Icon visible only one `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Button Action Snackbar not show properly `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Image Picker Show on Disabled State on LgnPhotoField `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Bottom Sheet Peek Height on Tablet Devices `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Bottom Sheet Footer Button on Tablet Devices `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`Logee Distribution`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`Logee Transportation`}</inlineCode>{` `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="4 April 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 1.29.0`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Tab Bar Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Success State on Password Field `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Badge Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Revamp Single Text Field Prefix and Suffix `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add Compability Background Color on Single Text Field `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: New Alert Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Theme`}</strong>{`: Add `}<inlineCode parentName="p">{`AGR`}</inlineCode>{` theme for `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Theme`}</strong>{`: Add `}<inlineCode parentName="p">{`AGL`}</inlineCode>{` theme for `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Theme`}</strong>{`: Add `}<inlineCode parentName="p">{`AQF`}</inlineCode>{` theme for `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Theme`}</strong>{`: Add `}<inlineCode parentName="p">{`MTN`}</inlineCode>{` theme for `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version: 1.0.8`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{` : Add Alert component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Remove Variant primary, responsive, and state focussed Anchor Text component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Remove Variant state focussed Button component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add development status information & design guideline link update in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="7 February 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 1.23.7`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Spinner Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add compatibility to change tint navigation icon on `}<inlineCode parentName="p">{`Toolbar`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Added component `}<inlineCode parentName="p">{`Bottom Sheet Dialog`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Added component `}<inlineCode parentName="p">{`Image View`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Added capability `}<inlineCode parentName="p">{`Button`}</inlineCode>{` to set transparent variant `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`Agree Fisheries`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`Agree Livestock`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New Theme`}</strong>{`: Added new theme support `}<inlineCode parentName="p">{`MyTens`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Divider Colors `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Icon Button not tint correctly `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`isEnabled`}</inlineCode>{` Status Wrong on Outline type Button `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`isEnabled`}</inlineCode>{` Status Wrong on Transparent type Button `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Limit Photo Not Work on `}<inlineCode parentName="p">{`MultiplePhotoField`}</inlineCode>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Anchor Text Padding `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`Bottom Sheet`}</inlineCode>{` not dismissed after pick photo from camera `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version: 1.0.8`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{` : Add Alert component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Remove Variant primary, responsive, and state focussed Anchor Text component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Remove Variant state focussed Button component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add development status information & design guideline link update in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="15 December 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 1.15.0`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Add `}<inlineCode parentName="p">{`compability`}</inlineCode>{` image permission to Android 13`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add Component `}<inlineCode parentName="p">{`Switch`}</inlineCode><br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add Component `}<inlineCode parentName="p">{`State View`}</inlineCode><br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add capability `}<inlineCode parentName="p">{`Chips`}</inlineCode>{` to set click listener on non filter type`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add capability `}<inlineCode parentName="p">{`Chips`}</inlineCode>{` to change background colors`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add capability `}<inlineCode parentName="p">{`Chips`}</inlineCode>{` to change stroke colors`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add capability `}<inlineCode parentName="p">{`Chips`}</inlineCode>{` to change text colors`}<br />{` `}<br />{`
`}<strong parentName="p">{`New Theme`}</strong>{`: Add New Theme Support `}<inlineCode parentName="p">{`Agree Culture`}</inlineCode><br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version: 1.0.5`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Change default and small size variant Switch component in Legion Project - UI Library - Mobile UI Component`}</p>
      </AccordionItem>
      <AccordionItem title="3 November 2022 " mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 1.8.6`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` New Button Ghost Variant `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` New Divider Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` New Snackbar Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` New Anchor Text Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` Color Foundation to latest `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` Add extension function for RecyclerView to support using Divider component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` Add compability snackbar with fragment `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` Add compability set horizontal orientation on RadioGroup and RadioContainer `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` Revamp Button Fill Variant `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` Revamp Button Outline Variant `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix :`}</strong>{` SearchView border not show `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix :`}</strong>{` Preview not shown on component CustomImageView `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix :`}</strong>{` Hint crashed component RadioContainer if hint is empty `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Bug Fix :`}</strong>{` MultiplePhotoField when enable status changed `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version: 1.0.4`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Change colors `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Remove line and change to text underline `}<inlineCode parentName="p">{`Anchor Text`}</inlineCode>{` component in Legion Project - UI Library - Mobile UI Component`}</p>
      </AccordionItem>
      <AccordionItem title="12 October 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 1.0.0`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: add variant with Icon on `}<inlineCode parentName="p">{`button`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: add disable state on `}<inlineCode parentName="p">{`MultiPhotoField`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: add compatibility to change `}<inlineCode parentName="p">{`chipBackgroundColor`}</inlineCode>{` of `}<inlineCode parentName="p">{`chips`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: add load from url in `}<inlineCode parentName="p">{`MultiplePhotoField`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: `}<inlineCode parentName="p">{`elevation`}</inlineCode>{` to latest design foundation `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: add variant with Icon on `}<inlineCode parentName="p">{`button`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: add disable state on `}<inlineCode parentName="p">{`MultiPhotoField`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: add compatibility to change `}<inlineCode parentName="p">{`chipBackgroundColor`}</inlineCode>{` of `}<inlineCode parentName="p">{`chips`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: add load from url in `}<inlineCode parentName="p">{`MultiplePhotoField`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: `}<inlineCode parentName="p">{`elevation`}</inlineCode>{` to latest design foundation `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add Listener when image on loading at `}<inlineCode parentName="p">{`MultiplePhotoField`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Improvements image caching on `}<inlineCode parentName="p">{`MultiplePhotoField`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{`: Add empty state when image not avalaibe on `}<inlineCode parentName="p">{`MultiplePhotoField`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: double `}<inlineCode parentName="p">{`bottom sheet`}</inlineCode>{` when dropdown overriden `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`padding`}</inlineCode>{` in prefix and suffix of `}<inlineCode parentName="p">{`textfield`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: secondary `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{` component Label not Show `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: double `}<inlineCode parentName="p">{`bottom sheet`}</inlineCode>{` when dropdown overriden `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: secondary `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{` component Label not Show `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: Image Rotation when pick image from camera `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`padding`}</inlineCode>{` in prefix and suffix of `}<inlineCode parentName="p">{`textfield`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`TimeField`}</inlineCode>{` wrong format text `}<br />{` `}<br />{`
`}<strong parentName="p">{`Bug Fix`}</strong>{`: `}<inlineCode parentName="p">{`MultiplePhotoField`}</inlineCode>{` not loop images data when DSL Declaration `}<br />{` `}<br />{`
`}<strong parentName="p">{`Revamp`}</strong>{`: `}<inlineCode parentName="p">{`Toolbar`}</inlineCode>{` Layout and mechanism `}<br />{` `}<br /></p>
        <h2><strong parentName="h2">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h2>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version : 1.0.2`}</strong>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`Button Group`}</inlineCode>{` component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Change all component color properties with new foundation `}<inlineCode parentName="p">{`color scale`}</inlineCode>{` `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Change variant style primary to `}<inlineCode parentName="p">{`solid`}</inlineCode>{` and secondary to `}<inlineCode parentName="p">{`soft`}</inlineCode>{` in `}<inlineCode parentName="p">{`button`}</inlineCode>{` component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new variant style `}<inlineCode parentName="p">{`outline`}</inlineCode>{` and `}<inlineCode parentName="p">{`transparent`}</inlineCode>{` in base and include the variant of `}<inlineCode parentName="p">{`button`}</inlineCode>{` component`}</p>
      </AccordionItem>
      <AccordionItem title="12 September 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Android (Legion-Android) - Release Version : 0.1.0`}</strong>{` `}<br />{` `}<br /></p>
        <p>{`Initial Release`}</p>
        <h2><strong parentName="h2">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h2>
        <p><strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`tooltip`}</inlineCode>{` component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`top bar`}</inlineCode>{` component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`slider`}</inlineCode>{` component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`images`}</inlineCode>{` componentin Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`badge`}</inlineCode>{` componentin Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`radio button`}</inlineCode>{` component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Added `}<inlineCode parentName="p">{`onboarding`}</inlineCode>{` pattern in Legion Project - Pattern Library Website`}</p>
      </AccordionItem>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      